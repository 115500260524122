import request from '@/utils/request'

export function getProvinces() {
  return request.get('/api/provinces')
}

export function getCities(params) {
  return request.get('/api/cities', { params })
}

export function getAreas(params) {
  return request.get('/api/areas', {params})
}

export function getShipping() {
  return request.get('/api/shippings')
}
