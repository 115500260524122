<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card>
      <v-toolbar
        flat
        color="transparent"
      >
        <v-text-field
          v-model="options.search"
          rounded
          dense
          outlined
          hide-details
          label="搜索"
          placeholder="按商户名、分组名搜索"
          :append-icon="icons.mdiMagnify"
          class="flex-grow-0"
          @click:append="getDesserts"
        />
        <v-spacer></v-spacer>
        <v-btn
          v-permission="['40001']"
          color="primary"
          dark
          @click="createBtnClick"
        >
          添加商户
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page.sync="options.pageSize"
        :page.sync="options.page"
        :server-items-length="totalDesserts"
        :loading="loading"
        :disable-sort="true"
        :footer-props="{itemsPerPageOptions: [10, 15, 20, 50, 100]}"
        @update:items-per-page="getDesserts"
        @update:page="getDesserts"
      >
        <template v-slot:item.price_list_name="{ item }">
          {{ item.price_list_name }}
          <v-btn
            v-permission="['40003']"
            icon
            x-small
            @click="priceListEditClick(item)"
          >
            <v-icon>{{ icons.mdiPencil }}</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.group_name="{ item }">
          {{ item.group_name }}
          <v-btn
            v-permission="['40003']"
            icon
            x-small
            @click="groupEditClick(item)"
          >
            <v-icon>{{ icons.mdiPencil }}</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.is_active="{ item }">
          <div class="d-flex align-center">
            <v-switch
              v-model="item.is_active"
              v-permission="['40003']"
              color="success"
              inset
              @change="changeStatus(item)"
            ></v-switch>
            <v-chip
              :color="item.is_active ? 'success' : 'error'"
              dark
            >
              {{ item.is_active ? '启用' : '禁用' }}
            </v-chip>
          </div>
        </template>
        <template
          v-slot:item.actions="{ item }"
        >
          <v-btn
            v-permission="['40002']"
            icon
            @click="itemClick(item)"
          >
            <v-icon>{{ icons.mdiArrowRightCircle }}</v-icon>
          </v-btn>
          <v-btn
            v-permission="['40004']"
            icon
            @click="deleteBtnClick(item)"
          >
            <v-icon>{{ icons.mdiDelete }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="editDialog"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card
        :loading="editDialogLoading"
        class="pa-sm-10"
      >
        <v-card-title class="justify-center text-h5">
          {{ !rowId ? '新增商户' : viewRow ? '查看商户': '编辑商户' }}
        </v-card-title>
        <v-card-text class="text-center mt-n2">
          {{ !rowId ? '创建一个新的商户' : viewRow ? '查看商户详细信息': '修改商户信息' }}
        </v-card-text>
        <v-card-text class="mt-5">
          <v-form
            ref="merchantFormRef"
            :disabled="viewRow"
            lazy-validation
          >
            <v-subheader class="justify-center">基本信息</v-subheader>
            <v-divider class="mb-2"></v-divider>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="row.name"
                  :counter="20"
                  :rules="[rules.required]"
                  label="商户名"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="row.leader"
                  :counter="20"
                  :rules="[rules.required]"
                  label="联系人"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="row.contact_information"
                  :counter="11"
                  :rules="[rules.required, rules.mobile]"
                  label="联系方式"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-menu
                  transition="scroll-y-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="row.op_time"
                      label="开业时间"
                      :append-icon="icons.mdiCalendar"
                      :rules="[rules.required]"
                      readonly
                      outlined
                      dense
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="row.op_time"
                    locale="zh-cn"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="row.price_list_name"
                  readonly
                  outlined
                  dense
                  label="价目表"
                  @click="priceListInputClick"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="row.group_name"
                  readonly
                  outlined
                  dense
                  label="商户组"
                  :rules="[rules.required]"
                  required
                  @click="groupInputClick"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="row.is_active"
                  :items="merchantStatus"
                  :rules="[rules.required]"
                  required
                  outlined
                  dense
                  label="商户状态"
                ></v-select>
              </v-col>
            </v-row>
            <v-subheader class="justify-center">地址信息</v-subheader>
            <v-divider class="mb-2"></v-divider>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="row.province"
                  item-text="name"
                  item-value="code"
                  :items="provincesList"
                  :loading="provinceLoading"
                  :rules="[rules.required]"
                  :no-data-text="provinceLoading ? '加载中......' : '暂无数据'"
                  label="省/直辖市"
                  required
                  outlined
                  dense
                  @click="getProvinces"
                  @change="changeProvinces"
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="row.city"
                  item-text="name"
                  item-value="code"
                  :items="citiesList"
                  :loading="cityLoading"
                  :rules="[rules.required]"
                  :no-data-text="!row.province ? '请先选择省份' : cityLoading ? '加载中......' : '暂无数据'"
                  label="城市"
                  outlined
                  dense
                  @click="getCities"
                ></v-select>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="row.county"
                  item-text="name"
                  item-value="code"
                  :items="areasList"
                  :loading="areaLoading"
                  :rules="[rules.required]"
                  :no-data-text="!row.city ? '请先选择城市' : areaLoading ? '加载中......' : '暂无数据'"
                  label="区/县"
                  outlined
                  dense
                  @click="getAreas"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="row.address"
                  :counter="50"
                  label="详细地址"
                  :rules="[rules.required]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-subheader class="justify-center">登录信息</v-subheader>
            <v-divider class="mb-2"></v-divider>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="row.username"
                  :counter="20"
                  label="用户名"
                  :rules="[rules.required]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="row.mobile"
                  :counter="11"
                  :rules="[rules.required, rules.mobile]"
                  label="手机号"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="row.password"
                  type="password"
                  label="登录密码"
                  :append-icon="rowId ? icons.mdiPencil : ''"
                  :readonly="rowId ? true : false"
                  :rules="[rules.required, rules.password]"
                  required
                  outlined
                  dense
                  @click:append="changePassword = true"
                ></v-text-field>
                <v-text-field
                  v-if="changePassword"
                  v-model="row.change_password"
                  type="password"
                  label="新密码"
                  :rules="[rules.required, rules.password]"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center mt-3">
          <v-btn
            v-if="viewRow"
            v-permission="['40003']"
            color="primary"
            dark
            @click="editClick"
          >
            编辑
          </v-btn>
          <v-btn
            v-else
            color="primary"
            dark
            @click="editDialogSubmit"
          >
            保存
          </v-btn>
          <v-btn
            outlined
            @click="editDialogCancel"
          >
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          删除商户同时将会删除该商户下的所有订单信息，确定要删除吗?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="cancelDelete"
          >
            取消
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItem"
          >
            确认
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="priceListDialog"
      max-width="500px"
    >
      <v-card :loading="priceListLoading">
        <v-toolbar
          flat
          color="transparent"
        >
          <v-text-field
            v-model="priceListOption.search"
            rounded
            dense
            outlined
            hide-details
            label="搜索"
            placeholder="按价目表名称搜索"
            :append-icon="icons.mdiMagnify"
            class="flex-grow-0"
            @click:append="getPriceLists"
          />
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-list
          flat
          subheader
        >
          <v-subheader class="justify-center">价目表</v-subheader>
          <v-divider></v-divider>
          <v-list-item-group color="primary" class="d-flex justify-start flex-wrap mx-4">
            <v-list-item
              v-for="item in priceLists"
              :key="item.id"
              class="flex-basis-33"
              @click="clickPriceList(item)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-card-actions class="justify-center">
          <v-pagination
            v-model="priceListOption.page"
            circle
            :length="Math.ceil(priceListTotal / priceListOption.pageSize)"
            total-visible="5"
            @input="getPriceLists"
          ></v-pagination>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="merchantGroupDialog"
      max-width="500px"
    >
      <v-card :loading="merchantGroupLoading">
        <v-toolbar
          flat
          color="transparent"
        >
          <v-text-field
            v-model="merchantGroupOption.search"
            rounded
            dense
            outlined
            hide-details
            label="搜索"
            placeholder="按商户组名称搜索"
            :append-icon="icons.mdiMagnify"
            class="flex-grow-0"
            @click:append="getMerchantGroups"
          />
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-list
          flat
          subheader
        >
          <v-subheader class="justify-center">商户组</v-subheader>
          <v-divider></v-divider>
          <v-list-item-group color="primary" class="d-flex justify-start flex-wrap mx-4">
            <v-list-item
              v-for="item in merchantGroups"
              :key="item.id"
              class="flex-basis-33"
              @click="clickGroup(item)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-card-actions class="justify-center">
          <v-pagination
            v-model="merchantGroupOption.page"
            circle
            :length="Math.ceil(merchantGroupsTotal / merchantGroupOption.pageSize)"
            total-visible="5"
            @input="getMerchantGroups"
          ></v-pagination>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
  getMerchants, addMerchant, updateMerchant, getMerchant, deleteMerchant, getMerchantGroups,
} from '@/api/merchant'
import { getProvinces, getCities, getAreas } from '@/api/city'
import { getPriceLists } from '@/api/goods'
import {
  mdiMagnify, mdiClose, mdiDelete, mdiArrowRightCircle, mdiCalendar, mdiPencil,
} from '@mdi/js'

import moment from 'moment'

export default {
  name: 'Merchant',
  data() {
    return {
      desserts: [],
      priceLists: [],
      merchantGroups: [],
      merchantGroupsTotal: 0,
      merchantStatus: [
        {
          text: '启用',
          value: true,
        },
        {
          text: '禁用',
          value: false,
        },
      ],
      rules: {
        required: value => !!value || '必填项.',
        mobile: value => /^1\d{10}$/.test(value) || '无效的手机号',
        password: value => /^(?![^a-zA-Z]+$)(?!\D+$){8,16}/.test(value) || '密码应为8-16位数字字母组合.',
      },
      priceListTotal: 0,
      totalDesserts: 0,
      editDialog: false,
      deleteDialog: false,
      priceListDialog: false,
      savePriceList: false,
      priceListLoading: false,
      merchantGroupDialog: false,
      merchantGroupLoading: false,
      saveGroup: false,
      editDialogLoading: true,
      changePassword: false,
      rowId: null,
      viewRow: false,
      dateShow1: false,
      provincesList: [],
      provinceLoading: false,
      citiesList: [],
      cityLoading: false,
      areasList: [],
      areaLoading: false,
      row: {
        name: '',
        province: null,
        city: null,
        county: null,
        address: '',
        op_time: moment().format('YYYY-MM-DD'),
        leader: '',
        contact_information: '',
        price_list: null,
        price_list_name: '',
        group: null,
        group_name: '',
        is_active: true,
        username: '',
        mobile: '',
        password: '',
        change_password: null,
      },
      loading: true,
      headers: [
        { text: '商户名', value: 'name' },
        { text: '价目表', value: 'price_list_name' },
        { text: '订单数', value: 'order_count' },
        { text: '联系人', value: 'leader' },
        { text: '联系方式', value: 'contact_information' },
        { text: '属组', value: 'group_name' },
        { text: '开业时间', value: 'op_time' },
        { text: '状态', value: 'is_active' },
        { text: '操作', align: 'center', value: 'actions' },
      ],
      icons: {
        mdiMagnify,
        mdiClose,
        mdiDelete,
        mdiArrowRightCircle,
        mdiCalendar,
        mdiPencil,
      },
      options: {
        page: 1,
        pageSize: 10,
        search: '',
      },
      priceListOption: {
        page: 1,
        pageSize: 20,
        search: '',
      },
      merchantGroupOption: {
        page: 1,
        pageSize: 20,
        search: '',
      },
    }
  },

  created() {
    this.getDesserts()
  },

  methods: {
    async getProvinces() {
      this.provinceLoading = true
      const { data } = await getProvinces()
      this.provincesList = data
      this.provinceLoading = false
    },
    changeProvinces() {
      this.row.city = null
      this.row.county = null
    },
    async getCities() {
      if (!this.row.province) {
        return
      }
      this.cityLoading = true
      const { data } = await getCities({ province_code: this.row.province })
      this.citiesList = data
      this.cityLoading = false
    },
    async getAreas() {
      if (!this.row.city) {
        return
      }
      this.areaLoading = true
      const { data } = await getAreas({ city_code: this.row.city })
      this.areasList = data
      this.areaLoading = false
    },
    priceListInputClick() {
      this.priceListOption.page = 1
      this.priceListOption.search = ''
      this.priceListDialog = true
      this.priceListLoading = true
      this.getPriceLists().then(() => {
        this.priceListLoading = false
      })
    },
    priceListEditClick(item) {
      this.rowId = item.id
      this.row = item
      this.savePriceList = true
      this.priceListInputClick()
    },
    async getPriceLists() {
      const { data } = await getPriceLists(this.priceListOption)
      this.priceLists = data.results
      this.priceListTotal = data.count
    },
    clickPriceList(item) {
      this.row.price_list = item.id
      this.row.price_list_name = item.name
      if (this.savePriceList) {
        updateMerchant(this.rowId, this.row).then(() => {
          this.priceListDialog = false
          this.savePriceList = false
          this.$toast.success('价目表更新成功')
          this.getDesserts()
        })
      } else {
        this.priceListDialog = false
        this.savePriceList = false
      }
    },
    groupInputClick() {
      this.merchantGroupOption.page = 1
      this.merchantGroupOption.search = ''
      this.merchantGroupDialog = true
      this.merchantGroupLoading = true
      this.getMerchantGroups().then(() => {
        this.merchantGroupLoading = false
      })
    },
    groupEditClick(item) {
      this.rowId = item.id
      this.row = item
      this.saveGroup = true
      this.groupInputClick()
    },
    async getMerchantGroups() {
      const { data } = await getMerchantGroups(this.merchantGroupOption)
      this.merchantGroups = data.results
      this.merchantGroupsTotal = data.count
    },
    clickGroup(item) {
      this.row.group = item.id
      this.row.group_name = item.name
      if (this.saveGroup) {
        updateMerchant(this.rowId, this.row).then(() => {
          this.merchantGroupDialog = false
          this.saveGroup = false
          this.$toast.success('价目表更新成功')
          this.getDesserts()
        })
      } else {
        this.merchantGroupDialog = false
        this.saveGroup = false
      }
    },
    changeStatus(item) {
      updateMerchant(item.id, item).then(() => {
        this.$toast.success('商户状态更新成功')
        this.getDesserts()
      })
    },
    getDesserts() {
      this.loading = true
      getMerchants(this.options).then(response => {
        this.desserts = response.data.results
        this.totalDesserts = response.data.count
        this.loading = false
      })
    },
    async itemClick(item) {
      this.editDialog = true
      this.editDialogLoading = true
      this.viewRow = true
      this.rowId = item.id
      const { data } = await getMerchant(item.id)
      this.row = data
      this.row.change_password = null
      await this.getProvinces()
      await this.getCities()
      await this.getAreas()
      this.editDialogLoading = false
    },
    deleteBtnClick(item) {
      this.rowId = item.id
      this.deleteDialog = true
    },
    cancelDelete() {
      this.rowId = null
      this.deleteDialog = false
    },
    async deleteItem() {
      await deleteMerchant(this.rowId)
      this.rowId = null
      this.deleteDialog = false
      this.$toast.success('删除成功')
      this.options.page = 1
      this.getDesserts()
    },
    async editClick() {
      this.viewRow = false
    },
    createBtnClick() {
      this.row.op_time = moment().format('YYYY-MM-DD')
      this.row.is_active = true
      this.editDialogLoading = false
      this.viewRow = false
      this.rowId = null
      this.editDialog = true
    },
    async editDialogSubmit() {
      if (!this.$refs.merchantFormRef.validate()) return
      if (this.rowId) {
        await updateMerchant(this.rowId, this.row)
        this.$toast.success('更新成功')
      } else {
        await addMerchant(this.row)
        this.$toast.success('创建成功')
      }
      this.getDesserts()
      this.changePassword = false
      this.$refs.merchantFormRef.reset()
      this.editDialog = false
    },
    editDialogCancel() {
      this.$refs.merchantFormRef.reset()
      this.changePassword = false
      this.editDialog = false
    },
  },
}
</script>
<style lang="sass">
  .flex-basis-33
      flex-basis: 33% !important

</style>
